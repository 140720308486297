// App.js
import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./CSS-Files/App.css"
import "./Loader.css"
const HomePage = lazy(() => import('./components/HomePage'));
const DesignComponent = lazy(() => import('./components/DesignComponent'));
const OptionsPage = lazy(() => import('./components/KitBuilder'));
const DesignsPage = lazy(() => import('./components/DesignPage'));
const NextPageComponent = lazy(() => import('./components/NextPageComponent'));
const Header = lazy(() => import('./components/Header'));
const FootballCategory = lazy(() => import('./pages/FootballCategory'));

const App = () => {
  return (
    <Router>
      <Suspense fallback={<div className="loader"></div>}>
        <Header />
        <Routes>
          <Route path="/kitbuilder" element={<OptionsPage />} />
          <Route path="/designs/:sport/:category" element={<DesignsPage />} />
          <Route path="/kitbuilder/:sport/:type" element={<OptionsPage />} />
          <Route path="/kitbuilder/:sport/:category" element={<OptionsPage />} />
          <Route path='kitbuilder/:sport' element={<FootballCategory />} />
          <Route
            path="/designs/:sport/:category/:subcategory"
            element={<DesignComponent />}
          />
          <Route
            path="/customizer/:sport/:category/:subcategory/:designId"
            element={<DesignComponent />}
          />
          <Route path="/productdetail" element={<DesignComponent />} />
          <Route
            path="/next-page"
            element={<NextPageComponent />}
          />
          <Route path="/" element={<HomePage />} />
          <Route path="/:sport/:category/:subcategory?" element={<DesignsPage />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;

